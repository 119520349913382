/**
 * Direttiva che crea un carousel basato su Slick carousel
 * Il $timeout Ã¨ necessario affinchÃ¨ la direttiva costruisca il componente
 */

angular.module('app').directive("slick", ($timeout) => {
    return {
        restrict: 'AE',
        scope: {
            settings: '=',
            initOnload: '@',
            data: '=',
            currentIndex: '=',
            arrows: '@',
            autoplay: '@',
            autoplaySpeed: '@',
            customPaging: '&',
            dots: '@',
            variableWidth: '@',
            infinite: '@',
            initialSlide: '@',
            onBeforeChange: '=',
            onAfterChange: '=',
            onInit: '&',
            onReInit: '&',
            onSetPosition: '&',
            responsive: '=',
            slide: '@',
            slidesToShow: '@',
            slidesToScroll: '@',
            speed: '@',
            swipe: '@',
            swipeToSlide: '@',
            prevArrow: '@',
            nextArrow: '@'
        },

        link: (scope: any, element: any, attrs: any) => {
            let destroySlick: Function;
            let initializeSlick: Function;
            let isInitialized: boolean;

            // Elimina il carousel dal DOM
            destroySlick = () => {
                return $timeout(() => {
                    let carousel: any;
                    carousel = $(element);
                    carousel.slick('unslick');
                    carousel.find('.slick-list').remove();
                    return carousel;
                });
            };

            // Inizializzazione
            initializeSlick = () => {
                return $timeout(() => {
                    let currentIndex: number;
                    let customPaging: any;
                    let carousel: any;

                    let numSlidesToShow: number = scope.slidesToShow ? parseInt(scope.slidesToShow, 10) : 3;
                    let numSlidesToScroll: number = scope.slidesToScroll ? parseInt(scope.slidesToScroll, 10) : 3;

                    // Collegamento all'elemento nel DOM
                    carousel = $(element);

                    if (scope.currentIndex != null) {
                        currentIndex = scope.currentIndex;
                    }

                    if(!scope.customPaging){
                        customPaging = (slick: any, index: number) => {
                            return scope.customPaging({
                                slick: slick,
                                index: index
                            });
                        }
                    };

                    // Configurazioni
                    carousel.slick({
                        arrows: scope.arrows ? scope.arrows : true,
                        autoplay: scope.autoplay ? scope.autoplay : false,
                        autoplaySpeed: scope.autoplaySpeed != null ? parseInt(scope.autoplaySpeed, 10) : 3000,
                        dots: scope.dots ? scope.dots : true,
                        infinite: scope.infinite ? scope.infinite : false,
                        initialSlide: scope.initialSlide || 0,
                        beforeChange: attrs.onBeforeChange ? scope.onBeforeChange : void 0,
                        onReInit: attrs.onReInit ? scope.onReInit : void 0,
                        onSetPosition: attrs.onSetPosition ? scope.onSetPosition : void 0,
                        responsive: scope.responsive || [
                            {
                                breakpoint: 1024,
                                settings: {
                                    slidesToShow: numSlidesToShow != null ? numSlidesToShow : 3,
                                    slidesToScroll: numSlidesToScroll != null ? numSlidesToScroll : 3,
                                    infinite: true,
                                    dots: true
                                }
                            },
                            {
                                breakpoint: 1000,
                                settings: {
                                    slidesToShow: numSlidesToShow != null ? numSlidesToShow : 2,
                                    slidesToScroll: numSlidesToScroll != null ? numSlidesToScroll : 2,
                                }
                            },
                            {
                                breakpoint: 718,
                                settings: {
                                    slidesToShow: numSlidesToShow != null ? numSlidesToShow : 1,
                                    slidesToScroll: numSlidesToScroll != null ? numSlidesToScroll : 1,
                                }
                            }
                        ],
                        slide: scope.slide || 'div',
                        slidesToShow: numSlidesToShow,
                        slidesToScroll: numSlidesToScroll,
                        speed: scope.speed != null ? parseInt(scope.speed, 10) : 300,
                        swipe: scope.swipe !== 'false',
                        swipeToSlide: scope.swipeToSlide === 'true',
                        prevArrow: scope.prevArrow ? $(scope.prevArrow) : void 0,
                        nextArrow: scope.nextArrow ? $(scope.nextArrow) : void 0,
                        variableWidth: scope.variableWidth ? scope.variableWidth === 'true'  : false
                    });

                    carousel.on('init', (slider: any) => {
                        // Se devo eseguire qualche funzione all'avvio dello slick, la eseguo
                        if (attrs.onInit) {
                            scope.onInit();
                        }
                        // aggiorno l'indice
                        if (currentIndex != null) {
                            return slider.slideHandler(currentIndex);
                        }
                    });

                    carousel.on('afterChange', (event: any, slick: any, currentSlide: any, nextSlide: any) => {
                        // Se devo eseguire qualche funzione dopo il cambiamento dello slider, la eseguo
                        if (scope.onAfterChange) {
                            scope.onAfterChange(event, slick, currentSlide, nextSlide);
                        }
                        // aggiorno l'indice
                        if (currentIndex != null) {
                            return scope.$apply(() => {
                                currentIndex = currentSlide;
                                return scope.currentIndex = currentSlide;
                            });
                        }
                    });

                    carousel.on('beforeChange', (event: any, slick: any, currentSlide: any, nextSlide: any) => {
                        // Se devo eseguire qualche funzione dopo il cambiamento dello slider, la eseguo
                        if (scope.onBeforeChange) {
                            scope.onBeforeChange(event, slick, currentSlide, nextSlide);
                        }
                        // aggiorno l'indice
                        if (currentIndex != null) {
                            return scope.$apply(() => {
                                currentIndex = currentSlide;
                                return scope.currentIndex = currentSlide;
                            });
                        }
                    });

                    return scope.$watch('currentIndex', (newVal: any, oldVal: any) => {
                        if (currentIndex != null && newVal != null && newVal !== currentIndex) {
                            return carousel.slick('slickGoTo', newVal);
                        }
                    });
                });
            };

            // Mostra il carousel solo quando i dati sono stati caricati
            if (scope.initOnload) {
                isInitialized = false;
                return scope.$watch('data', (newVal: any, oldVal: any) => {
                    if (newVal != null) {
                        if (isInitialized) {
                            destroySlick();
                        }
                        initializeSlick();
                        return isInitialized = true;
                    }
                });
            } else {
                return initializeSlick();
            }
        }
    }
});